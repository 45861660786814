import React, { useState, useEffect } from 'react';
import useGlobal from '../store';

const SearchForm = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, tracking, orders, requestStatus, processing, currentCampaign, channels } = globalState;
  const [channel, setChannel] = useState('ALS');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!currentCampaign) {
      globalActions.altidollarsRefundCampaign.getAltidollarsRefundCurrentCampaign();
    }
  }, [globalActions, currentCampaign]);

  async function getAndSaveOrder(orderName, channel) {
    const order = await globalActions.db.getOrder(orderName, channel);
    globalActions.utils.updateOrderStore(order);
    return order;
  }

  async function fetchOrderPerReturn(returnsViaTracking) {
    let orders;
    await Promise.all(
      returnsViaTracking.map(async returnObj => {
        await globalActions.db.getPriceMatches({ key: 'order', value: returnObj.order_name });
        return await getAndSaveOrder(returnObj.order_name, returnObj.channel);
      })
    ).then(values => {
      globalActions.items.setAvailableItems('processing');
      orders = values;
    });
    return orders.filter(order => order !== undefined);
  }

  async function fetchReturnsViaBundleIDs(returnsViaOrderName) {
    const returnsViaBundleIDs = await Promise.all(
      returnsViaOrderName.map(async returnObj => {
        if (returnObj.bundle_id) {
          const returnObjects = await globalActions.db.getReturns({
            key: 'bundle',
            value: returnObj.bundle_id
          });
          return returnObjects;
        } else {
          return returnObj;
        }
      })
    ).then(values => values);

    return returnsViaBundleIDs;
  }

  function validateOrderCreationThreshold(orders) {
    const now = new Date();
    const returnPeriod = Number(process.env.REACT_APP_RETURN_PERIOD_IN_DAYS);
    if (!returnPeriod) {
      throw new Error('Return period is not configures');
    }
    const newReturnPeriod = Number(process.env.REACT_APP_NEW_RETURN_PERIOD_IN_DAYS);
    const newReturnPeriodDate = Date.parse(process.env.REACT_APP_NEW_RETURN_PERIOD_DATE);
    const isNewPeriodConfigured = !!(newReturnPeriod && newReturnPeriodDate);
    const ordersDays = orders.map(order => {
      const creationDate = new Date(order.created_at);
      const daysSinceOrderCreation = Math.round((now - creationDate) / (1000 * 60 * 60 * 24));
      const daysToReturn = isNewPeriodConfigured && creationDate.getTime() > newReturnPeriodDate ? newReturnPeriod : returnPeriod;
      return {
        order: order.order_number,
        days: daysSinceOrderCreation,
        canReturn: daysSinceOrderCreation <= daysToReturn,
      };
    });

    return ordersDays.some(el => !el.canReturn) ? ordersDays : null;
  }

  function displayPopUp(returnObj, orders) {
    let popUpContent = [];

    if (returnObj) {
      const receptionNote = returnObj.reception_note;
      const customerTags = returnObj.customer_tags;
      if (receptionNote && receptionNote.length > 0) {
        popUpContent.push(receptionNote);
      }

      if (customerTags && customerTags.includes('RNC')) {
        popUpContent.push(text.return_processing.rnc);
      }
    }

    const beyondReturnPeriod = validateOrderCreationThreshold(orders);
    if (beyondReturnPeriod) {
      beyondReturnPeriod.forEach(el => {
        popUpContent.push(
          `${text.return_processing.order_beyond_return_days_a} ${el.order} ${text.return_processing.order_beyond_return_days_b} ${el.days} ${text.return_processing.order_beyond_return_days_c}`
        );
      });
    }

    popUpContent.length > 0 && globalActions.notification.showDialog('Reception note', popUpContent.join(' | '));
  }

  async function getReturnsAndOrdersViaOrderName(orderName, channel) {
    globalActions.notification.hideMessage();
    const returnsViaOrderName = await globalActions.db.getReturns(
      {
        key: 'order',
        value: orderName
      },
      channel
    );

    if (returnsViaOrderName.length > 0) {
      const returnsViaBundleIDs = await fetchReturnsViaBundleIDs(returnsViaOrderName);
      const returns = returnsViaBundleIDs.flat();

      const orders = await fetchOrderPerReturn(returns);
      orders &&
        displayPopUp(
          returns.find(returnObj => returnObj.status === 'open' || returnObj.status === 'received'),
          orders
        );
    } else {
      const order = await getAndSaveOrder(orderName, channel);
      if (order) {
        globalActions.items.setAvailableItems('processing', channel);
        displayPopUp(false, [order]);
      } else {
        globalActions.utils.clearProcessStates();
        globalActions.notification.showMessage(text.return_processing.error_no_order_found, 'error');
      }
      await globalActions.db.getPriceMatches({ key: 'order', value: orderName });
    }
  }

  async function submitSearchTracking(e) {
    e.preventDefault();

    setLoading(true);

    const tracking = globalActions.utils.parseCarrierTrackingBarcode(e.target.tracking.value);
    globalActions.utils.updateStateProperty('carrier', tracking.carrier);

    if (tracking.number && tracking.number.length > 0) {
      const returnsViaTracking = await globalActions.db.getReturns({
        key: 'tracking',
        value: tracking.number
      });

      if (returnsViaTracking.length > 0) {
        globalActions.processing.setTracking({
          tracking_number: tracking.number
        });

        const trackingData = await globalActions.db.getTracking(tracking.number);

        let orders = [];
        if (trackingData) {
          const { order_name, channel } = trackingData;
          const order = await getAndSaveOrder(order_name, channel);
          if (order) {
            orders = [order];
            globalActions.items.setAvailableItems('processing', channel);
          }
          await globalActions.db.getPriceMatches({ key: 'order', value: order_name });
        } else {
          orders = await fetchOrderPerReturn(returnsViaTracking);
        }

        if (orders?.length > 0) {
          orders && displayPopUp(returnsViaTracking[0], orders);
        } else if (orders && orders.length === 0) {
          globalActions.notification.showMessage(text.return_processing.received_package_found, 'success');
        }
      } else {

        const trackingObj = await globalActions.db.getTracking(tracking.number);

        if (trackingObj) {
          getReturnsAndOrdersViaOrderName(trackingObj.order_name, trackingObj.channel);
        } else {
          globalActions.notification.showMessage(text.return_processing.error_tracking_not_found, 'error');
        }
      }
    } else {
      globalActions.notification.showMessage(text.return_processing.error_please_enter_tracking, 'error');
    }

    setLoading(false);
    const $orderInput = document.querySelector('[name="order_name"]');
    if ($orderInput) $orderInput.focus();
  }

  function submitSearchOrder(e) {
    e.preventDefault();

    setLoading(true);

    const orderName = e.target.order_name.value;

    if (orderName.length > 0 && channel.length > 0) {
      getReturnsAndOrdersViaOrderName(orderName, channel);
      setChannel('ALS');
    } else {
      globalActions.notification.showMessage(text.return_processing.error_empty_input, 'error');
    }

    setLoading(false);
  }

  if (loading || requestStatus === 'LOADING' || processing) return <p>{text.return_processing.loading}</p>;

  return (
    <>
      {orders.length === 0 && !tracking && (
        <form onSubmit={submitSearchTracking}>
          <input
            name="tracking"
            placeholder={text.return_processing.tracking_number}
            autoComplete="off"
            autoFocus={!loading}
          />
          <button type="submit" style={{ display: 'none' }}>
            {text.return_processing.search}
          </button>
        </form>
      )}

      {tracking && tracking.tracking_number && (
        <h4>
          {text.return_processing.tracking_number}: {tracking.tracking_number}
        </h4>
      )}

      {orders.length === 1 && (
        <h4>
          {text.return_processing.order}: {orders[0].channel} {orders[0].order_number}
        </h4>
      )}

      {orders.length > 1 && (
        <div className="orders-title">
          <h4>{text.return_processing.order}s: </h4>
          <ul>
            {orders.map(order => (
              <li key={order._id}>
                {order.channel} {order.order_number}{' '}
              </li>
            ))}
          </ul>
        </div>
      )}

      {orders.length === 0 && tracking && tracking.tracking_number.length > 0 && (
        <form onSubmit={submitSearchOrder}>
          <input name="order_name" placeholder={text.return_processing.order_input_placeholder} autoComplete="off" />
          <button type="submit" style={{ display: 'none' }}>
            {text.return_processing.search}
          </button>

          <div>
            <select value={channel} onChange={e => setChannel(e.target.value)}>
              {Object.keys(channels).map(name => (
                <option key={channels[name].channel_id} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </form>
      )}
    </>
  );
};

export default SearchForm;
