import React from 'react';
import useGlobal from '../store';

const ActionsButtons = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, selectedItems } = globalState;

  function voidReturnObj(e) {
    e.preventDefault();
    globalActions.notification.showDialog(
      text.return_processing.confirm,
      text.return_processing.clear_form,
      globalActions.utils.clearProcessStates
    );
  }

  function submitProcess(e) {
    e.preventDefault();
    if (selectedItems.length > 0) {
      globalActions.notification.showDialog(text.return_processing.confirm, text.return_processing.process_return, () =>
        globalActions.processing.processReturnObjects('processing')
      );
    } else {
      globalActions.notification.showMessage(text.return_processing.error_no_items, 'error');
    }
  }

  return (
    <>
      <button onClick={voidReturnObj}>{text.return_processing.void}</button>
      <button className="blue" onClick={submitProcess}>
        {text.return_processing.process_button} {selectedItems.length > 0 ? `${selectedItems.length} items` : null}
      </button>
    </>
  );
};

export default ActionsButtons;
