import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import useGlobal from '../store';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [accessToken, setAccessToken] = useState();
  const [unparsedAccessToken, setUnparsedAccessToken] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const { agentEmail } = globalState;

  useEffect(() => {
    async function initAuth0() {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        const accessToken = await auth0FromHook.getTokenSilently();
        setAccessToken(parseJwt(accessToken));
        setUnparsedAccessToken(accessToken);

        !agentEmail && user && user.email && globalActions.utils.updateStateProperty('agentEmail', user.email);
      }

      setLoading(false);
    }
    initAuth0();
    // eslint-disable-next-line
  }, []);

  async function loginWithPopup(params = {}) {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    const accessToken = await auth0Client.getTokenSilently();
    setAccessToken(parseJwt(accessToken));
    setUnparsedAccessToken(accessToken);
    setIsAuthenticated(true);
  }

  async function handleRedirectCallback() {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    const accessToken = await auth0Client.getTokenSilently();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
    setAccessToken(parseJwt(accessToken));
    setUnparsedAccessToken(accessToken);
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        accessToken,
        unparsedAccessToken,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
