import React /* , { useState } */ from 'react';
import useGlobal from '../store';

const Receiving = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, requestStatus } = globalState;
  // const [/* priority */, setPriority] = useState(null);

  async function stampReturnObjects(returnObjects, chosenTracking) {
    await Promise.all(
      returnObjects.map(returnObj => {
        if (returnObj.received_at === null || returnObj.received_at === '') {
          const update = chosenTracking
            ? {
              status: 'received',
              bundle_tracking_numbers: [],
              tracking_number: chosenTracking
            }
            : {
              status: 'received'
            };

          const res = globalActions.db.updateReturnObj(update, returnObj._id);

          return res;
        } else if (returnObj.received_at.length > 0) {
          return { status: 'SUCCESS' };
        } else {
          return { status: 'ERROR' };
        }
      })
    ).then(values => {
      if (values.length > 0 && values.every(el => el.status === 'SUCCESS')) {
        globalActions.notification.showMessage(text.return_reception.success_updating, 'success');
        setTimeout(() => {
          globalActions.notification.hideMessage();
        }, 3000);
      } else {
        globalActions.notification.showMessage(text.return_reception.failed_updating, 'error');
      }
    });
  }

  async function createReturnObject(trackingData) {
    let response;
    if (trackingData) {
      response = await globalActions.processing.receiveReturn(trackingData);
    } else {
      response = await globalActions.processing.receiveReturn();
    }
    if (response?.status === 'SUCCESS') {
      globalActions.utils.clearProcessStates();
      globalActions.notification.showMessage(text.return_reception.success_creating, 'success');
    } else {
      globalActions.utils.clearProcessStates();
      globalActions.notification.showMessage(text.return_reception.failed_creating, 'error');
    }
  }

  function displayPopUp(returnObj) {
    const receptionNote = returnObj.reception_note;
    const customerTags = returnObj.customer_tags ? returnObj.customer_tags : '';

    let popUpContent = [];

    if (receptionNote && receptionNote.length > 0) {
      popUpContent.push(receptionNote);
    }

    if (customerTags.includes('RNC')) {
      popUpContent.push(text.return_reception.rnc);
    }

    popUpContent.length > 0 &&
      globalActions.notification.showDialog(text.return_reception.reception_note, popUpContent.join(' | '));
  }

  async function processTracking(tracking) {
    const returnObjects = await globalActions.db.getReturns({
      key: 'tracking',
      value: tracking.number
    });

    if (returnObjects.length > 0) {
      stampReturnObjects(returnObjects);
      displayPopUp(returnObjects[0]);
    } else {
      globalActions.utils.updateStateProperty('carrier', tracking.carrier);
      const trackingData = await globalActions.db.getTracking(tracking.number);

      if (trackingData) {
        const orderReturnObjects = await globalActions.db.getReturns(
            { key: 'order', value: trackingData.order_name },
            trackingData.channel
        );

        if (orderReturnObjects.length > 0) {
          stampReturnObjects(orderReturnObjects, tracking.number);
        } else {
          createReturnObject(trackingData);
        }
      } else {
        createReturnObject();
      }
    }
  }

  function handleSearch(e) {
    e.preventDefault();
    globalActions.utils.clearProcessStates();

    globalActions.utils.updateStateProperty('requestStatus', 'LOADING');

    //setPriority(null);
    const tracking = globalActions.utils.parseCarrierTrackingBarcode(e.target.tracking.value);

    if (tracking.carrier) {
      processTracking(tracking);
    } else {
      globalActions.utils.updateStateProperty('requestStatus', 'INITIAL');

      globalActions.notification.showDialog(
        text.return_reception.confirm,
        `${text.return_reception.error_invalid_tracking_a} ${tracking.number} ${text.return_reception.error_invalid_tracking_b}`,
        () => processTracking(tracking)
      );
    }
  }

  return (
    <>
      <main className="container">
        <section className="section">
          {requestStatus === 'LOADING' && <p>{text.return_reception.loading}</p>}

          {requestStatus !== 'LOADING' && (
            <form onSubmit={handleSearch}>
              <input
                className="input"
                name="tracking"
                placeholder={text.return_reception.tracking_input_placeholder}
                autoComplete="off"
                autoFocus={true}
              />

              <button className="button" type="submit" style={{ display: 'none' }}>
                {text.return_reception.submit}
              </button>
            </form>
          )}

          {/* priority > 0 && (
            <h2 className="reception-priority">
              Priority: {priority}
              <span onClick={() => setPriority(null)}>x</span>
            </h2>
          ) */}
        </section>
      </main>
    </>
  );
};

export default Receiving;
