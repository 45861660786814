import axios from 'axios';

export const getAltidollarsRefundCurrentCampaign = async store => {
  store.setState({ campaignFetchStatus: 'LOADING' });
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/altidollars_refund_campaign_search`, {
      headers: store.state.requestHeaders
    });
    const currentCampaign = response.data;
    const campaignFetchStatus = currentCampaign.length === 0 ? 'EMPTY' : 'SUCCESS';

    if (campaignFetchStatus === 'SUCCESS') {
      store.setState({ currentCampaign });
    }
    store.setState({ campaignFetchStatus });
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const campaignFetchStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.setState({ campaignFetchStatus });
  }
};

export const postAltidollarsRefundCurrentCampaign = async (store, payload) => {
  store.setState({ campaignFetchStatus: 'LOADING' });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_ENDPOINT}/altidollars_refund_campaign_create`,
      JSON.stringify(payload),
      { headers: store.state.requestHeaders }
    );
    const campaignFetchStatus = response.status !== 201 ? 'ERROR' : 'SUCCESS';
    store.setState({ campaignFetchStatus, currentCampaign: null });
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const campaignFetchStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.setState({ campaignFetchStatus });
  }
};
