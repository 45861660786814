import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useGlobal from '../store';

const Dialogs = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, dialog } = globalState;
  const [isDisabled, setIsDisabled] = useState(false);

  function confirm(e) {
    e.preventDefault();
    setIsDisabled(true);
    globalActions.notification.confirm(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 1500);
  }

  function cancel(e) {
    e.preventDefault();
    globalActions.notification.confirm(false);
  }

  const dialogContent = !dialog.open
    ? []
    : [
        <div>
          <div className="dialog-content">
            <h2>{dialog.title}</h2>
            <div className="dialog-content__message">{dialog.message}</div>
            {dialog.action === undefined || dialog.action === null ? (
              <form className="one-button">
                <button className="action" onClick={cancel}>
                  Ok
                </button>
              </form>
            ) : (
              <form className="two-button">
                <button onClick={cancel}>{text.dialog.cancel}</button>
                <button className="action" onClick={confirm} disabled={isDisabled}>
                  Ok
                </button>
              </form>
            )}
          </div>
        </div>
      ];

  return (
    <TransitionGroup>
      {dialogContent.map((item, index) => (
        <CSSTransition key={index} timeout={100} classNames="dialog-container dialog">
          {item}
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default Dialogs;
