import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import useGlobal from '../store';

const Messages = () => {
  const [globalState, globalActions] = useGlobal();
  const { message } = globalState;

  const messageContent = !message.open
    ? []
    : [
        <div className={`message-background ${message.type}`}>
          <div className="message-content">
            <div>{message.message}</div>
          </div>
        </div>
      ];

  return (
    <TransitionGroup>
      {messageContent.map((item, index) => (
        <CSSTransition
          onClick={() => globalActions.notification.hideMessage()}
          key={index}
          timeout={100}
          classNames="message-container message"
        >
          {item}
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default Messages;
