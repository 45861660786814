import React, { useState, useEffect } from 'react';
import './App.css';
import { useAuth0 } from './authService/react-auth0-wrapper';
import userPermissions from './utils/userPermissions';
import useGlobal from './store';
import ReceivingSearchForm from './components/ReceivingSearchForm';
import ProcessingSearchForm from './components/ProcessingSearchForm';
import ProcessingItems from './components/ProcessingItems';
import ActionsButtons from './components/ProcessingButtons';
import Dialogs from './components/Dialogs';
import Messages from './components/Messages';
import RestockingFees from './components/ProcessingRestockingFees';
import IdleLogout from './components/IdleLogout';

function App() {
  const { loading, isAuthenticated, user, loginWithRedirect, logout, accessToken, unparsedAccessToken } = useAuth0();
  const [globalState, globalActions] = useGlobal();
  const { lang, text, mode, agentEmail, requestHeaders } = globalState;
  const [displayMenu, setDisplayMenu] = useState(false);

  useEffect(() => {
    !agentEmail && user && user.email && globalActions.utils.updateStateProperty('agentEmail', user.email);
  }, [loading, isAuthenticated, user, agentEmail, globalActions.utils]);

  useEffect(() => {
    if (!requestHeaders && unparsedAccessToken) {
      const headers = { 'X-API-KEY': unparsedAccessToken };
      globalActions.utils.updateStateProperty('requestHeaders', headers);
    }
  }, [requestHeaders, unparsedAccessToken, globalActions]);

  const selectMode = e => {
    globalActions.utils.clearProcessStates();
    globalActions.notification.hideMessage();
    if (mode === 'processing') {
      globalActions.utils.setScanMode('receiving');
    } else if (mode === 'receiving') {
      globalActions.utils.setScanMode('processing');
    } else if (mode === null) {
      globalActions.utils.setScanMode(e.target.value);
    }
  };

  return (
    <div className="App">
      <header className={process.env.REACT_APP_ENDPOINT.includes('staging') ? 'header-staging' : ''}>
        <div>
          <h1>Cerberus</h1>
          {process.env.REACT_APP_ENDPOINT.includes('staging') && <p>Staging</p>}
          <p>v 1.9.5</p>
          {mode && <p className="mode-title">{text.menu[mode]}</p>}
        </div>

        {isAuthenticated && user && (
          <>
            <button onClick={() => setDisplayMenu('show')} className="nav-hamb">
              <div className="nav-hamb__icon">
                <div className="nav-hamb__icon__line"></div>
                <div className="nav-hamb__icon__line"></div>
                <div className="nav-hamb__icon__line"></div>
              </div>
            </button>
          </>
        )}
      </header>

      {!isAuthenticated && !loading && (
        <div className="mode-buttons">
          <img src="/images/cerberus_square.png" alt="cerberus-logo" width="280px"></img>
          <button value="login" onClick={loginWithRedirect}>
            {text.homepage.sign_in}
          </button>
          {lang === 'en' && <button onClick={globalActions.utils.toggleLang}>FR</button>}
          {lang === 'fr' && <button onClick={globalActions.utils.toggleLang}>EN</button>}
        </div>
      )}

      {isAuthenticated && user && (
        <div className={`nav-menu__wrapper ${displayMenu}`}>
          <div className="nav-menu">
            <button
              onClick={() => {
                setDisplayMenu('');
                const trackingInput = document.querySelector('[name="tracking"]');
                trackingInput && trackingInput.focus();
              }}
              className="nav-x"
            >
              <div className="nav-x__icon">
                <div className="nav-x__icon__line"></div>
                <div className="nav-x__icon__line"></div>
              </div>
            </button>

            <p className="user-title">
              {text.menu.logged_as}: {user.given_name && user.given_name.length > 0 ? user.given_name : user.email}
            </p>

            <div className="mode-buttons">
              <button
                value="receiving"
                onClick={e => {
                  selectMode(e);
                  setDisplayMenu('');
                }}
              >
                {text.menu.receiving}
              </button>
              <button
                value="processing"
                onClick={e => {
                  selectMode(e);
                  setDisplayMenu('');
                }}
              >
                {text.menu.processing}
              </button>
              {lang === 'en' && <button onClick={globalActions.utils.toggleLang}>FR</button>}
              {lang === 'fr' && <button onClick={globalActions.utils.toggleLang}>EN</button>}
              <button className="logout" onClick={() => logout({ returnTo: process.env.REACT_APP_BASE_URL })}>
                {text.menu.sign_out}
              </button>
            </div>
          </div>
        </div>
      )}

      {loading && <p>{text.homepage.loading}</p>}

      {isAuthenticated &&
        user &&
        accessToken &&
        userPermissions(accessToken, ['admin', 'return_manager', 'return_agent']) && (
          <>
            {mode === null && (
              <main className="mode-buttons">
                <button value="receiving" onClick={selectMode}>
                  {text.menu.receiving}
                </button>
                <button value="processing" onClick={selectMode}>
                  {text.menu.processing}
                </button>
              </main>
            )}

            {mode === 'processing' && (
              <>
                <main>
                  <ProcessingSearchForm />
                  <ProcessingItems />
                  <Messages />
                </main>
                <footer>
                  <RestockingFees />
                  <ActionsButtons />
                </footer>
              </>
            )}

            {mode === 'receiving' && (
              <>
                <ReceivingSearchForm />
                <Messages />
              </>
            )}

            <Dialogs />
          </>
        )}

      {isAuthenticated &&
        user &&
        accessToken &&
        !userPermissions(accessToken, ['admin', 'return_manager', 'return_agent']) && (
          <p className="user-title">{text.homepage.no_access}</p>
        )}

      {isAuthenticated && user && accessToken && <IdleLogout signoutTime="20" />}
    </div>
  );
}

export default App;
