import axios from 'axios';

export const getOrderID = async (store, orderName, metafields) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/shopify_order_id?channel=${
        store.state.ordersCaptureChannel
      }&order_name=${orderName}${metafields ? '&metafields=1' : ''}`,
      { headers: store.state.requestHeaders }
    );

    if (response.data.altidollarsRefundsHistory) {
      store.setState({ altidollarsRefundsHistory: response.data.altidollarsRefundsHistory });
    }

    response.data.captured = response.data.captured === 'true' ? true : false;
    return response.data;
  } catch (error) {
    error.response.data.captured = error.response.data.captured === 'true' ? true : false;
    return error.response.data;
  }
};
