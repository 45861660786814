const en = {
  dialog: {
    cancel: 'Cancel'
  },
  store: {
    error_fetching_returns: 'Error fetching returns. Please contact IT',
    error_fetching_orders: 'Error fetching orders. Please contact IT',
    success_updating_tracking: 'Success updating tracking data.',
    error_invalid_sku: 'Invalid or unavailable sku.',
    error_fetching_sku: 'Failed to fetch sku. Please contact IT.',
    error_empty_reasons_list: 'Returns reasons list is empty.',
    error_fetching_reasons: 'Failed to fetch returns reasons. Please contact IT.',
    error_label_create_fail: 'Failed to create prepaid label. Please contact IT',
    error_fetching_price_matches: 'Error fetching price-matches. Please contact IT',
    item_unavailable: 'Item unavailable - please input new order',
    item_unavailable_in_order: 'Item not available in that order. Please enter a new order number.',
    warranty: 'Warranty. Please process manually.',
    return_post_success: 'Success sending return(s).',
    error_return_post_fail: 'Failed to send return(s)',
    sku_update_success: 'Success updating SKUs.',
    error_sku_update_fail: 'Failed to update SKUs.',
    error_no_sku_available: 'No item available for that order.',
    error_marketplace_title: 'Marketplace article',
    error_marketplace:
      'Please contact the Customer Service to process the Marketplace refund. Do not refund on Shopify.'
  },
  homepage: {
    sign_in: 'Sign in',
    loading: 'Loading...',
    no_access: "Sorry, you don't have access to this app. Please contact technical support."
  },
  menu: {
    logged_as: 'Logged as',
    receiving: 'Reception',
    processing: 'Processing',
    sign_out: 'Sign out'
  },
  return_reception: {
    title: 'Return receiving',
    submit: 'Submit',
    tracking_input_placeholder: 'Tracking number',
    error_invalid_tracking_a: 'Tracking',
    error_invalid_tracking_b: 'is not a known carrier (Canada Post or Fedex). Do you still want to process it?',
    confirm: 'Confirm',
    rnc: 'RNC: abusive client',
    success_creating: 'Success creating and receiving return.',
    failed_creating: 'Failed to create and receive return.',
    success_updating: 'Success receiving return.',
    failed_updating: 'Failed to receive return.',
    loading: 'Loading...',
    reception_note: 'Reception note'
  },
  return_processing: {
    title: 'Return processing',
    confirm: 'Confirm',
    clear_form: 'Clear the form?',
    process_return: 'Process return?',
    error_no_items: 'Please scan item(s) first.',
    process_button: 'Process',
    void: 'Void',
    error_invalid_sku: 'Invalid - a sku or upc should have at least 11 numbers',
    loading: 'Loading...',
    warranty: 'Warranty. Please process manually.',
    doa: 'DOA. Please process manually.',
    looks_different_from_image: 'Looks different from image on site. Please process manually.',
    received_wrong_item: 'Received wrong item. Please process manually.',
    inaccurate_description: 'Inaccurate website description. Please process manually.',
    poor_quality: 'Item of poor quality. Please process manually.',
    input_sku_placeholder: 'Enter UPC or SKU',
    add: 'Add',
    order: 'Order',
    order_input_placeholder: 'Order number',
    no_refund: 'Do not refund',
    altidollars_refund: 'Note: this article will be refunded in Alti Dollars',
    validate: 'Validate',
    no_refund_tlh: 'Not refunded (TLH sku)',
    no_refund_mp: 'Not refunded (Marketplace item)',
    no_refund_instalments: 'Manual refund (Paybright/Sezzle)',
    remove: 'Remove',
    restocking_fee_exception: 'Return shipping fee exception',
    rnc: 'RNC: abusive client',
    order_beyond_return_days_a: 'Order',
    order_beyond_return_days_b: 'was made',
    order_beyond_return_days_c: 'days ago.',
    error_no_order_found: 'No order found.',
    received_package_found: 'Received package found. Please enter order number.',
    error_tracking_not_found: 'Tracking not found. Please enter order number.',
    error_please_enter_tracking: 'Please enter tracking number or order reference.',
    tracking_number: 'Tracking number',
    search: 'Search',
    error_empty_input: 'Please enter valid order number',
    altidollar_refund: 'Reimburse in Alti Dollars',
    cx_agent_exception: 'Please leave checked (CX agent exception)'
  }
};

export default en;
