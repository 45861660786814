import React, { useEffect } from 'react';
import { useAuth0 } from '../authService/react-auth0-wrapper';

const IdleLogout = ({ signoutTime }) => {
  const { logout } = useAuth0();

  useEffect(() => {
    let logoutTimeout;

    function setTimeouts() {
      logoutTimeout = setTimeout(logout, parseInt(signoutTime) * 60000);
    }

    function clearTimeouts() {
      if (logoutTimeout) clearTimeout(logoutTimeout);
    }

    const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    function resetTimeout() {
      clearTimeouts();
      setTimeouts();
    }
    // eslint-disable-next-line
    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();

    return () => {
      // eslint-disable-next-line
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, [logout, signoutTime]);

  return <React.Fragment />;
};

export default IdleLogout;
