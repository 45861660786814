import React from 'react';

const ProductImg = ({ item, size, margin }) => {
  const wrapperStyle = {
    backgroundColor: 'white',
    margin: margin,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: size,
    maxHeight: size,
    minWidth: size,
    maxWidth: size
  };

  const imgStyle = {
    height: 'auto',
    maxHeight: size,
    width: 'auto',
    maxWidth: size
  };

  function imgSrc() {
    if (item.sku === 'membership') {
      return '/images/membership.jpg';
    } else if (item.image_src) {
      return item.image_src;
    } else {
      return '';
    }
  }

  return (
    <div style={wrapperStyle}>
      <img
        style={imgStyle}
        src={imgSrc()}
        alt={item.variant_title || 'No image available'}
        onError={e => (e.target.src = '/images/no-img-available.webp')}
      />
    </div>
  );
};

export default ProductImg;
