import axios from 'axios';

export const getReturns = async (store, query, channel) => {
  const queryKey = query.key;
  const queryValue = query.value;

  store.setState({ requestStatus: 'LOADING' });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/return_search?${queryKey}=${queryValue}${
        channel ? '&channel=' + channel : ''
      }`,
      { headers: store.state.requestHeaders }
    );
    const newReturns = response.data;

    const updatedReturns = newReturns.filter(returnObj => !store.state.returns.some(el => el._id === returnObj._id));
    store.setState({
      returns: [...store.state.returns, ...updatedReturns],
      requestStatus: 'SUCCESS'
    });
    return newReturns;
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const requestStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.actions.notification.showMessage(store.state.text.store.error_fetching_returns, 'error');
    store.setState({ requestStatus });
    return [];
  }
};

async function postAgentEvent(store, payload) {
  try {
    await axios.post(`${process.env.REACT_APP_ENDPOINT}/agent_event_create`, JSON.stringify(payload), {
      headers: store.state.requestHeaders
    });
  } catch (error) {
    console.log(error);
  }
}

export const updateReturnObj = async (store, payload, id) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_ENDPOINT}/return_update?key=${id}`,
      JSON.stringify(payload),
      { headers: store.state.requestHeaders }
    );
    const returnObjStatus = response.status !== 201 ? 'ERROR' : 'SUCCESS';

    returnObjStatus === 'SUCCESS' &&
      postAgentEvent(store, {
        agent_email: store.state.agentEmail,
        created_at: null,
        event_type: 'updated',
        target_id: id,
        target_status: payload.status,
        target_type: 'return_object',
        update: payload
      });

    return {
      status: returnObjStatus
    };
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const returnObjStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    return {
      status: returnObjStatus
    };
  }
};

export const postReturnObj = async (store, payload) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/return_create`, JSON.stringify(payload), {
      headers: store.state.requestHeaders
    });
    const returnObjStatus = response.status !== 201 ? 'ERROR' : 'SUCCESS';

    returnObjStatus === 'SUCCESS' &&
      postAgentEvent(store, {
        agent_email: store.state.agentEmail,
        created_at: null,
        event_type: 'created',
        target_id: response.data.insertedId,
        target_status: payload.status,
        target_type: 'return_object',
        target_value: payload
      });

    return {
      newReturnObjId: response.data.insertedId,
      status: returnObjStatus
    };
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const returnObjStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    return {
      status: returnObjStatus
    };
  }
};

export const getOrder = async (store, key, channel) => {
  store.setState({ requestStatus: 'LOADING' });
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/order_search?key=${key}${channel ? '&channel=' + channel : ''}`,
      { headers: store.state.requestHeaders }
    );
    store.setState({ requestStatus: 'SUCCESS' });
    return response.data[0];
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const requestStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.actions.notification.showMessage(store.state.text.store.error_fetching_orders, 'error');
    store.setState({ requestStatus });
  }
};

export const getTracking = async (store, trackingNumber) => {
  store.setState({ requestStatus: 'LOADING' });
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/tracking_search?key=${trackingNumber}`, {
      headers: store.state.requestHeaders
    });
    const trackings = response.data;
    const trackingData = trackings.length === 0 ? { tracking_number: trackingNumber } : trackings[0];

    store.setState({ tracking: trackingData, requestStatus: 'SUCCESS' });

    return trackings.length === 0 ? false : trackings[0];
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const requestStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.setState({ requestStatus });
  }
};

export const getSkuFromUPC = async (store, upc) => {
  store.actions.notification.hideMessage();
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/variant_search?key=${upc}`, {
      headers: store.state.requestHeaders
    });

    const skuWithouthMK = response.data.filter(el => !el.sku.includes('MK'));
    const skuObj = skuWithouthMK[0] ? skuWithouthMK[0] : false;

    if (skuObj) {
      return skuObj;
    } else if (!skuObj && response.data.find(el => el.sku.includes('MK'))) {
      store.actions.notification.showMessage('Marketplace sku.', 'error');
      return false;
    } else {
      store.actions.notification.showMessage(store.state.text.store.error_invalid_sku, 'error');
      return false;
    }
  } catch (error) {
    console.log(error);
    store.actions.notification.showMessage(store.state.text.store.error_fetching_sku, 'error');
    return false;
  }
};

export const getReturnsList = async store => {
  store.setState({ requestStatus: 'LOADING' });

  try {
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/return_list${formatEndpointParams(store)}`, {
      headers: store.state.requestHeaders
    });
    const listData = response.data;

    store.setState({ listData, listType: 'returns', requestStatus: 'SUCCESS' });
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const requestStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.setState({ requestStatus });
  }
};

export const getReturnsListTagsData = async store => {
  if (store.state.listTags) return;

  try {
    const tags = [
      { name: 'refundFails', param: 'refunded.failed' },
      { name: 'restockFails', param: 'restocked.failed' },
      { name: 'exceptions', param: 'status.exception' }
    ];

    const tagsData = await Promise.all(
      tags.map(async tag => {
        const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/return_list?limit=1&${tag.param}=1`, {
          headers: store.state.requestHeaders
        });

        return {
          name: tag.name,
          total: response.data.meta ? response.data.meta.total : 0
        };
      })
    );

    const listTags = {};
    tagsData.forEach(tag => {
      listTags[tag.name] = { total: tag.total };
    });

    store.setState({ listTags });
  } catch (error) {
    console.log(error);
  }
};

export const getReasonsList = async store => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/reason_list`, {
      headers: store.state.requestHeaders
    });
    const reasons = response.data;

    if (reasons.length > 0) {
      store.setState({ reasons });
    } else {
      store.actions.notification.showMessage(store.state.text.store.error_empty_reasons_list, 'error');
    }
  } catch (error) {
    console.log(error);
    store.actions.notification.showMessage(store.state.text.store.error_fetching_reasons, 'error');
  }
};

export const prepaidLabelCP = async (store, bundleId, ordersString) => {
  try {
    let response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/prepaid_label_cp?key=${bundleId}&orders_string=${ordersString}`,
      { headers: store.state.requestHeaders }
    );
    const pdfBase64 = response.data.xml.pdf.$binary.base64;
    const success = response.data.xml.cp_status.indexOf('200') > -1;

    if (success) {
      return pdfBase64;
    } else if (!success) {
      store.actions.notification.showMessage(store.state.text.store.error_label_create_fail, 'error');
      return false;
    }
  } catch (error) {
    console.log(error);
    store.actions.notification.showMessage(store.state.text.store.error_label_create_fail, 'error');
    return false;
  }
};

export const sendReturnAuthorizationEmail = async (store, bundleId) => {
  try {
    await axios.get(`${process.env.REACT_APP_ENDPOINT}/prepaid_label_email?key=${bundleId}`, {
      headers: store.state.requestHeaders
    });
    return 'SUCCESS';
  } catch (error) {
    console.log(error);
    store.actions.notification.showMessage(store.state.text.store.return_authorization_email_fail, 'error');
    return false;
  }
};

export const getPriceMatches = async (store, query) => {
  const queryKey = query.key;
  const queryValue = query.value;

  store.setState({ requestStatus: 'LOADING' });

  try {
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/price_match_search?${queryKey}=${queryValue}`, {
      headers: store.state.requestHeaders
    });
    const priceMatches = response.data;

    const updatedPriceMatches = priceMatches.filter(
      pmObj => !store.state.priceMatches.some(el => el._id === pmObj._id)
    );
    store.setState({
      priceMatches: [...store.state.priceMatches, ...updatedPriceMatches],
      requestStatus: 'SUCCESS'
    });

    return priceMatches;
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const requestStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.actions.notification.showMessage(store.state.text.store.error_fetching_price_matches, 'error');
    store.setState({ requestStatus });
  }
};

export const postPriceMatchObject = async (store, payload) => {
  store.setState({ requestStatus: 'LOADING' });
  try {
    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}/price_match_create`, JSON.stringify(payload), {
      headers: store.state.requestHeaders
    });

    const requestStatus = response.status !== 201 ? 'ERROR' : 'SUCCESS';

    requestStatus === 'SUCCESS' &&
      postAgentEvent(store, {
        agent_email: store.state.agentEmail,
        created_at: null,
        event_type: 'created',
        target_id: response.data.insertedId,
        target_status: payload.status,
        target_type: 'price_match_object',
        target_value: payload
      });

    return {
      status: requestStatus,
      insertedId: response.data.insertedId
    };
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const requestStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.actions.notification.showMessage(error.response.data.message);
    store.setState({ requestStatus });
    return {
      status: requestStatus
    };
  }
};

export const getPriceMatchesList = async store => {
  store.setState({ requestStatus: 'LOADING' });

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/price_match_list${formatEndpointParams(store)}`,
      { headers: store.state.requestHeaders }
    );
    const listData = response.data;

    store.setState({ listData, listType: 'price-matches', requestStatus: 'SUCCESS' });
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const requestStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.setState({ requestStatus });
  }
};

export const getPriceHistory = async (store, sku, channel) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ENDPOINT}/pricematch_histoprice_search?sku=${sku}&channel=${channel}`,
      { headers: store.state.requestHeaders }
    );
    const priceHistory = response.data;
    return priceHistory;
  } catch (error) {
    console.log(error);
    store.actions.notification.showMessage(store.state.text.store.error_fetching_price_matches, 'error');
    return false;
  }
};

export const getMarketplaceSellers = async (store, brand_shop_ids) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/mp_sellers_search?shopids=${brand_shop_ids.join(',')}`, {
      headers: store.state.requestHeaders
    });

    const sellersData = response.data;
    const sellersNames = [...new Set(sellersData.map(el => el.seller_name))];
    const newSellers = sellersNames.map(name => sellersData.find(el => el.seller_name === name));
    store.setState({ sellers: [...store.state.sellers, ...newSellers] });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

function formatEndpointParams(store) {
  const filtersQueryString = store.actions.utils.filtersStateToQueryString(store.state.objectsQueryFilters);
  const settingsQueryString = store.actions.utils.filtersStateToQueryString(store.state.objectsQuerySettings);
  return filtersQueryString.length > 0
    ? '?' + settingsQueryString + '&' + filtersQueryString
    : '?' + settingsQueryString;
}
