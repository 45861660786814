const fr = {
  dialog: {
    cancel: 'Annuler'
  },
  store: {
    error_fetching_returns: 'Échec de téléchargement des retours. Veuillez contacter les TIs',
    error_fetching_orders: 'Échec de téléchargement des commandes. Veuillez contacter les TIs',
    success_updating_tracking: 'Données de repérage mises à jours avec succès.',
    error_invalid_sku: 'SKU non-valide ou non-disponible.',
    error_fetching_sku: 'Échec de téléchargement du SKU. Veuillez contacter les TIs.',
    error_empty_reasons_list: 'La liste des raisons de retour est vide.',
    error_fetching_reasons: 'Échec de téléchargement des raisons de retour. Veuillez contacter les TIs.',
    error_label_create_fail: 'Échec de création du bordereau de retour. Veuillez contacter les TIs.',
    error_fetching_price_matches: 'Échec de téléchargement des ajustements de prix. Veuillez contacter les TIs',
    item_unavailable: 'Article non-disponible - entrez le # de commande',
    item_unavailable_in_order:
      "Cet article n'est pas disponible dans cette commande. Veuillez entrer un nouveau numéro de commande.",
    warranty: 'Garrantie. Veuillez procéder manuellement.',
    return_post_success: 'Retour(s) envoyé(s) avec succès',
    error_return_post_fail: "Échec d'envoi du/des retour(s)",
    sku_update_success: 'SKUs mis à jour avec succès.',
    error_sku_update_fail: 'Échec de mise à jour des SKUs',
    error_no_sku_available: 'Aucun article disponible pour cette commande.',
    error_marketplace_title: 'Article Marketplace',
    error_marketplace:
      'Veuillez contacter le Service à la clientèle pour procéder au remboursement. Ne pas rembourser dans Shopify.'
  },
  homepage: {
    sign_in: 'Se connecter',
    loading: 'Chargement...',
    no_access: "Désolé, vous n'avez pas les accès pour cette application. Veuillez contacter le support technique."
  },
  menu: {
    logged_as: 'Agent connecté',
    receiving: 'Réception',
    processing: 'Traitement',
    sign_out: 'Se déconnecter'
  },
  return_reception: {
    title: 'Recevoir un retour',
    submit: 'Soumettre',
    tracking_input_placeholder: 'Numéro de repérage',
    error_invalid_tracking_a: 'Le numéro de repérage',
    error_invalid_tracking_b:
      "n'est pas d'un service connu (Postes Canada ou Fedex). Voulez-vous quand même le traiter?",
    confirm: 'Confirmez',
    rnc: 'RNC: client abusif',
    success_creating: 'Retour créé et réceptionné avec succès',
    failed_creating: 'Échec de création et réception du retour',
    success_updating: 'Retour réceptionné avec succès',
    failed_updating: 'Échec de réception du retour',
    loading: 'Chargement...',
    reception_note: 'Note de réception'
  },
  return_processing: {
    title: 'Traiter un retour',
    confirm: 'Confirmez',
    clear_form: 'Effacer le formulaire?',
    process_return: 'Compléter le traitement du retour?',
    error_no_items: 'Veuillez scanner au moins un article.',
    process_button: 'Traiter',
    void: 'Effacer',
    error_invalid_sku: 'Invalide - un SKU ou UPC devrait avoir au moins 11 chiffres.',
    loading: 'Chargement...',
    warranty: 'Garantie, veuillez procéder manuellement.',
    doa: 'Article défectueux, endommagé ou qui ne fonctionne pas. Veuillez procéder manuellement.',
    looks_different_from_image: 'Différent de l\'image sur le site internet. Veuillez procéder manuellement.',
    received_wrong_item: 'Reçu le mauvais article. Veuillez procéder manuellement.',
    inaccurate_description: 'Mauvaise description de produit. Veuillez procéder manuellement.',
    poor_quality: 'Article de mauvaise qualité. Veuillez procéder manuellement.',
    input_sku_placeholder: 'Entrez UPC ou SKU',
    add: 'Ajouter',
    order: 'Commande',
    order_input_placeholder: 'Numéro de commande',
    no_refund: 'Ne pas rembourser',
    altidollars_refund: 'Note: cet article sera remboursé en Alti Dollars',
    validate: 'Valider',
    no_refund_tlh: 'Non remboursé (SKU TLH)',
    no_refund_mp: 'Non remboursé (SKU Marketplace)',
    no_refund_instalments: 'Remboursement manuel (Paybright/Sezzle)',
    remove: 'Enlever',
    restocking_fee_exception: 'Exception du frais de retour',
    rnc: 'RNC: client abusif',
    order_beyond_return_days_a: 'La commande',
    order_beyond_return_days_b: 'a été faite il y a',
    order_beyond_return_days_c: 'jours.',
    error_no_order_found: 'Commande introuvable',
    received_package_found: 'Paquet reçu trouvé. Veuillez entrer le numéro de commande.',
    error_tracking_not_found: 'Numéro de repérage introuvable. Veuillez entrer le numéro de commande.',
    error_please_enter_tracking: 'Veuillez entrer le numéro de repérage ou la référence de commande.',
    tracking_number: 'Numéro de repérage',
    search: 'Chercher',
    error_empty_input: 'Veuillez entrer un numéro de commande valide.',
    altidollar_refund: 'Rembourser en Alti Dollars',
    cx_agent_exception: "Ne pas décocher (exception d'agent CX)"
  }
};

export default fr;
