export const getExpectedRefund = (store, restockingFee, refundedItems, priceMatches, orderObject) => {
  const expectedRefundState = JSON.parse(
    JSON.stringify({
      restockingFee,
      items: refundedItems,
      // Order initial transactions
      orderAmountPaidInMoney: 0,
      orderAmountPaidInExpiCred: 0,
      orderAmountPaidInAltidollars: 0,
      // Refunds calculation
      itemsRefundInMoney: 0,
      itemsRefundInAltidollars: 0,
      itemsRefundTotalBeforeTaxes: 0,
      totalRefundBeforeTaxes: 0,
      // Applying taxes
      taxLines: null,
      totalTaxRate: 0,
      taxesTotal: 0,
      totalRefundAfterTaxes: 0,
      // Allocating transaction amounts
      totalRefundInMoneyAfterTaxes: 0,
      totalRefundInAltidollarsAfterTaxes: 0,
      expirableCreditsBalance: 0,
      nonRefundableManualBalance: 0
    })
  );

  setTransactions(expectedRefundState, orderObject);
  setPastRefunds(expectedRefundState, orderObject, store.state.altidollarsRefundsHistory);
  setPastPriceMatches(expectedRefundState, priceMatches, orderObject);
  setTotalTaxRate(expectedRefundState, orderObject);
  setAmountsBeforeTaxes(expectedRefundState, refundedItems);
  applyRestockingFee(expectedRefundState);
  addTaxesToAmounts(expectedRefundState);
  setRefundedBalances(expectedRefundState);

  storeExpectedRefund(store, expectedRefundState, orderObject.order_number.toString(), refundedItems, restockingFee);

  return expectedRefundState;
};

export const addExpectedRefund = (store, payload) => {
  const currentCopy = [...store.state.expectedRefunds];
  const index = currentCopy.findIndex(el => el.order === payload.order);
  if (index > -1) currentCopy.splice(index, 1);

  store.setState({ expectedRefunds: [payload, ...currentCopy] });
};

function round(amount) {
  return Math.round(amount * 100) / 100;
}

function setTotalTaxRate(expectedRefundState, orderObject) {
  const taxLines = orderObject.line_items[0].tax_lines;
  expectedRefundState.taxLines = taxLines;
  expectedRefundState.totalTaxRate = taxLines.map(tax => tax.rate).reduce((a, b) => a + b, 0);
}

function addTaxes(origin, totalTaxRate) {
  return round(origin * totalTaxRate + origin);
}

function setTransactions(expectedRefundState, orderObject) {
  const orderTransactionMoney = orderObject.transactions.find(
    transaction =>
      (transaction.kind === 'capture' || transaction.gateway === 'paybright' || transaction.gateway === 'sezzle') &&
      transaction.status === 'success' && transaction.kind !== 'refund'
  );

  const altiDollarEligibleEndChars = ['alti', 'adgc'];
  const orderTransactionAltidollars = orderObject.transactions.filter(
    el => el.gateway === 'gift_card' && altiDollarEligibleEndChars.includes(el.receipt.gift_card_last_characters)
      && el.status === 'success'
  );

  const altidollarsSalesValue = orderTransactionAltidollars
    .filter(el => el.kind === 'sale')
    .map(el => (el.amount ? Number(el.amount) : 0))
    .reduce((a, b) => a + b, 0);
  const altidollarsRefundsValue = orderTransactionAltidollars
    .filter(el => el.kind === 'refund')
    .map(el => (el.amount ? Number(el.amount) : 0))
    .reduce((a, b) => a + b, 0);

  const totalAltiDollarsSpent = altidollarsSalesValue - altidollarsRefundsValue;

  const orderTransactionExpiCredRefunds = orderObject.transactions.filter(
    el =>
      el.gateway === 'gift_card' &&
      el.receipt.gift_card_last_characters === 'cred' &&
      el.status === 'success' &&
      el.kind === 'refund'
  );
  const orderTransactionExpiCredSales = orderObject.transactions.filter(
    el =>
      el.gateway === 'gift_card' &&
      el.receipt.gift_card_last_characters === 'cred' &&
      el.status === 'success' &&
      el.kind === 'sale'
  );

  const expiCredRefunds = orderTransactionExpiCredRefunds
    .map(el => (el.amount ? Number(el.amount) : 0))
    .reduce((a, b) => a + b, 0);
  const expiCredSales = orderTransactionExpiCredSales
    .map(el => (el.amount ? Number(el.amount) : 0))
    .reduce((a, b) => a + b, 0);

  expectedRefundState.orderAmountPaidInMoney = orderTransactionMoney ? Number(orderTransactionMoney.amount) : 0;
  expectedRefundState.orderAmountPaidInAltidollars = totalAltiDollarsSpent;
  expectedRefundState.orderAmountPaidInExpiCred = expiCredSales - expiCredRefunds;
}

function setPastRefunds(expectedRefundState, orderObject, orderAltidollarsRefundsHistory) {
  const moneyManualRefunds = orderObject.transactions.filter(
    transaction =>
      transaction.kind === 'refund' && transaction.status === 'success' && transaction.gateway !== 'gift_card'
  );
  const totalManualMoneyRefundsAmount = moneyManualRefunds
    .map(el => (el.amount ? Number(el.amount) : 0))
    .reduce((a, b) => a + b, 0);

  const totalManualAltidollarsRefundsAmount = orderAltidollarsRefundsHistory
    .map(el => (el.amount ? Number(el.amount) : 0))
    .reduce((a, b) => a + b, 0);

  expectedRefundState.orderAmountPaidInMoney -= totalManualMoneyRefundsAmount;
  expectedRefundState.orderAmountPaidInAltidollars -= totalManualAltidollarsRefundsAmount;

  if (expectedRefundState.orderAmountPaidInAltidollars < 0) {
    expectedRefundState.orderAmountPaidInMoney -= Math.abs(expectedRefundState.orderAmountPaidInAltidollars);
    expectedRefundState.orderAmountPaidInAltidollars = 0;
  }
}

function setPastPriceMatches(expectedRefundState, priceMatches, orderObject) {
  const orderPriceMatches = priceMatches.filter(pm => pm.order_name === orderObject.order_number.toString());

  if (orderPriceMatches.length > 0) {
    expectedRefundState.items.forEach(returnedItem => {
      const itemPriceMatches = orderPriceMatches.filter(orderPM =>
        orderPM.items.some(el => el.line_item_id === returnedItem.line_item_id)
      );

      if (itemPriceMatches.length > 0) {
        const mostRecentPriceMatch = itemPriceMatches.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        )[0];

        const itemPMData = mostRecentPriceMatch.items.find(el => el.line_item_id === returnedItem.line_item_id);

        returnedItem.paid_price = itemPMData.requested_price;
      }
    });
  }
}

function setAmountsBeforeTaxes(expectedRefundState, refundedItems) {
  expectedRefundState.itemsRefundInMoney = expectedRefundState.items
    .filter(item => !item.altidollar_refund)
    .map(item => {
      const isRefundException =
        refundedItems.find(el => el.line_item_id === item.line_item_id).refund_exception;

      return isRefundException ? 0 : item.paid_price;
    })
    .reduce((a, b) => a + b, 0);

  expectedRefundState.itemsRefundInAltidollars = expectedRefundState.items
    .filter(item => item.altidollar_refund)
    .map(item => {
      const isRefundException = refundedItems.find(el => el.line_item_id === item.line_item_id).refund_exception;

      return isRefundException ? 0 : item.paid_price;
    })
    .reduce((a, b) => a + b, 0);

  expectedRefundState.itemsRefundTotalBeforeTaxes =
    expectedRefundState.itemsRefundInMoney + expectedRefundState.itemsRefundInAltidollars;
  expectedRefundState.totalRefundBeforeTaxes = expectedRefundState.itemsRefundTotalBeforeTaxes;

  if (expectedRefundState.totalRefundBeforeTaxes < 0) {
    expectedRefundState.itemsRefundInMoney = 0;
    expectedRefundState.itemsRefundInAltidollars = 0;
    expectedRefundState.totalRefundBeforeTaxes = 0;
  }

  // Adjust original balances
  expectedRefundState.orderAmountPaidInMoney = round(
    expectedRefundState.orderAmountPaidInMoney -
    addTaxes(expectedRefundState.itemsRefundInAltidollars, expectedRefundState.totalTaxRate)
  );
  expectedRefundState.orderAmountPaidInAltidollars = round(
    expectedRefundState.orderAmountPaidInAltidollars +
    addTaxes(expectedRefundState.itemsRefundInAltidollars, expectedRefundState.totalTaxRate)
  );
}

function applyRestockingFee(expectedRefundState) {
  if (expectedRefundState.totalRefundBeforeTaxes <= 0) return;

  expectedRefundState.totalRefundBeforeTaxes -= expectedRefundState.restockingFee;

  expectedRefundState.itemsRefundInAltidollars -= expectedRefundState.restockingFee;
  if (expectedRefundState.itemsRefundInAltidollars < 0) {
    expectedRefundState.itemsRefundInMoney -= Math.abs(expectedRefundState.itemsRefundInAltidollars);
    expectedRefundState.itemsRefundInAltidollars = 0;
  }

  expectedRefundState.orderAmountPaidInExpiCred = round(
    expectedRefundState.orderAmountPaidInExpiCred -
    addTaxes(expectedRefundState.restockingFee, expectedRefundState.totalTaxRate)
  );

  if (expectedRefundState.orderAmountPaidInExpiCred < 0) {
    expectedRefundState.orderAmountPaidInAltidollars -= Math.abs(expectedRefundState.orderAmountPaidInExpiCred);
    expectedRefundState.orderAmountPaidInExpiCred = 0;
  }

  if (expectedRefundState.orderAmountPaidInAltidollars < 0) {
    expectedRefundState.orderAmountPaidInMoney -= Math.abs(expectedRefundState.orderAmountPaidInAltidollars);
    expectedRefundState.orderAmountPaidInAltidollars = 0;
  }
}

function addTaxesToAmounts(expectedRefundState) {
  expectedRefundState.taxesTotal = round(expectedRefundState.totalRefundBeforeTaxes * expectedRefundState.totalTaxRate);

  expectedRefundState.totalRefundAfterTaxes = addTaxes(
    expectedRefundState.totalRefundBeforeTaxes,
    expectedRefundState.totalTaxRate
  );
  expectedRefundState.totalRefundInMoneyAfterTaxes = addTaxes(
    expectedRefundState.itemsRefundInMoney,
    expectedRefundState.totalTaxRate
  );
  expectedRefundState.totalRefundInAltidollarsAfterTaxes = addTaxes(
    expectedRefundState.itemsRefundInAltidollars,
    expectedRefundState.totalTaxRate
  );
}

function setRefundedBalances(expectedRefundState) {
  if (expectedRefundState.orderAmountPaidInMoney < expectedRefundState.totalRefundAfterTaxes) {
    expectedRefundState.totalRefundInAltidollarsAfterTaxes = round(
      expectedRefundState.totalRefundAfterTaxes - expectedRefundState.orderAmountPaidInMoney
    );
    expectedRefundState.totalRefundInMoneyAfterTaxes = expectedRefundState.orderAmountPaidInMoney;
  }

  if (expectedRefundState.orderAmountPaidInAltidollars < expectedRefundState.totalRefundInAltidollarsAfterTaxes) {
    expectedRefundState.expirableCreditsBalance = round(
      expectedRefundState.totalRefundInAltidollarsAfterTaxes - expectedRefundState.orderAmountPaidInAltidollars
    );
    expectedRefundState.totalRefundInAltidollarsAfterTaxes = expectedRefundState.orderAmountPaidInAltidollars;
  }
  if (expectedRefundState.orderAmountPaidInExpiCred - expectedRefundState.expirableCreditsBalance < 0) {
    expectedRefundState.nonRefundableManualBalance = Math.abs(
      expectedRefundState.expirableCreditsBalance - expectedRefundState.orderAmountPaidInExpiCred
    );
    expectedRefundState.expirableCreditsBalance = expectedRefundState.orderAmountPaidInExpiCred;
  }

  if (round(expectedRefundState.nonRefundableManualBalance) === 0.01) {
    expectedRefundState.nonRefundableManualBalance = 0;
  }
}

function storeExpectedRefund(store, expectedRefundState, orderName, refundedItems, restockingFee) {
  const tax_lines = expectedRefundState.taxLines.map(el => {
    const amount =
      expectedRefundState.totalTaxRate > 0
        ? round((expectedRefundState.taxesTotal * el.rate) / expectedRefundState.totalTaxRate)
        : 0;

    return {
      title: el.title,
      tax_rate: el.rate,
      amount
    };
  });

  const data = {
    items: {
      quantity: refundedItems.length,
      total: round(expectedRefundState.itemsRefundTotalBeforeTaxes)
    },
    restocking_fee: restockingFee,
    total_before_taxes: round(expectedRefundState.totalRefundBeforeTaxes),
    tax_lines,
    total_after_taxes: round(expectedRefundState.totalRefundAfterTaxes),
    expected_transactions: [
      {
        type: 'money',
        amount: round(expectedRefundState.totalRefundInMoneyAfterTaxes)
      },
      {
        type: 'altidollars',
        amount: round(expectedRefundState.totalRefundInAltidollarsAfterTaxes)
      },
      {
        type: 'expi_credit_balance',
        amount: round(expectedRefundState.expirableCreditsBalance)
      },
      {
        type: 'manual_refund_balance',
        amount: round(expectedRefundState.nonRefundableManualBalance)
      }
    ]
  };

  store.actions.expectedRefund.addExpectedRefund({ order: orderName, data });
}
