import React from 'react';
import useGlobalHook from 'use-global-hook';
import { getCookie } from '../utils/cookies';
import setLangText from '../utils/lang';
import * as actions from './actions';
import { Channels } from '../channels_config.js';

const langCookieResult = getCookie('cerberus_lang').length > 0 ? getCookie('cerberus_lang') : 'en';

const initialState = {
  lang: langCookieResult,
  text: setLangText(langCookieResult),
  agentEmail: null,
  requestHeaders: null,
  mode: null,
  requestStatus: 'INITIAL',
  returns: [],
  tracking: null,
  carrier: null,
  orders: [],
  availableItems: [],
  selectedItems: [],
  restockingFeeException: false,
  dialog: {
    open: false,
    message: '',
    action: null
  },
  message: {
    open: false,
    message: '',
    type: ''
  },

  // Return form
  expectedRefunds: [],
  reasons: [],
  prepaidLabelNote: '',
  receptionNote: '',
  newReturnType: 'standard',
  returnLabelType: 'digital-label',
  altidollarsRefundsHistory: [],
  addQRcodeToEmail: false,
  sellers: [],
  selectedSeller: null,
  als_warehouse_return: false,

  // Price-match
  creditCardException: false,
  notShippedYet: false,
  priceMatches: [],
  membership: null,
  discountCode: {
    title: '',
    value: '',
    value_type: 'percentage'
  },
  priceMatchNote: '',

  // List data
  listData: null,
  listType: null,
  objectsQuerySettings: [{ name: 'limit', value: 12, type: 'value' }],
  objectsQueryFilters: [],
  listTags: null,

  // Altidollars refund campaign tool
  currentCampaign: null,
  bypassedAltidollarsRefundCampaign: false,

  // Channels Config Obj
  channels: Channels
};

const useGlobal = useGlobalHook(React, initialState, actions);

export default useGlobal;
